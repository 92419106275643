.dblist ul {
  margin: 0;
  padding: 0 0 0 2em;
}

.dblist > ul.clist {
  padding: 0;
}

ul.clist {
  list-style: none !important;
  padding: 0em 0 0 1em;
}

.clist a.dbentry,
a.cfolder,
a.collapsed {
  background-image: url(/++plone++bootstrap-icons/caret-right.svg) !important;
  background-position: 0 1px 0 0 !important;
  background-repeat: no-repeat !important;
  padding-left: 24px !important;
}

.clist a.cfolder {
  background-image: url(/++plone++bootstrap-icons/dash-square.svg) !important;
  display: inline-block;
  line-height: 1.4em;
}

.clist a.collapsed {
  background-image: url(/++plone++bootstrap-icons/plus-square.svg) !important;
  display: inline-block;
  line-height: 1.4em;
}

/* CSS */
#linkchecker .row,
#linkchecker .container {
  margin-bottom: 1em;
}

#linkchecker a {
  display: block;
  margin: 0.5em;
  text-decoration: underline;
  line-height: 1.5em;
}

#linkchecker button {
  margin-top: 3px;
}

#linkchecker img {
  border: 0px;
  padding: 5px;
}

