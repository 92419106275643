.dblist ul {
  margin: 0;
  padding: 0 0 0 2em;
}

.dblist > ul.clist {
  padding: 0;
}

ul.clist {
  list-style: none !important;
  padding: 0em 0 0 1em;
}

.clist a.dbentry,
a.cfolder,
a.collapsed {
  background-image: url(/++plone++bootstrap-icons/caret-right.svg) !important;
  background-position: 0 1px 0 0 !important;
  background-repeat: no-repeat !important;
  padding-left: 24px !important;
}

.clist a.cfolder {
  background-image: url(/++plone++bootstrap-icons/dash-square.svg) !important;
  display: inline-block;
  line-height: 1.4em;
}

.clist a.collapsed {
  background-image: url(/++plone++bootstrap-icons/plus-square.svg) !important;
  display: inline-block;
  line-height: 1.4em;
}

/* CSS */
#linkchecker .row,
#linkchecker .container {
  margin-bottom: 1em;
}

#linkchecker a {
  display: block;
  margin: 0.5em;
  text-decoration: underline;
  line-height: 1.5em;
}

#linkchecker button {
  margin-top: 3px;
}

#linkchecker img {
  border: 0px;
  padding: 5px;
}


/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInZkdnpnLmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQSIsImZpbGUiOiJ2ZHZ6Zy5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuZGJsaXN0IHVsIHtcbiAgbWFyZ2luOiAwO1xuICBwYWRkaW5nOiAwIDAgMCAyZW07XG59XG5cbi5kYmxpc3QgPiB1bC5jbGlzdCB7XG4gIHBhZGRpbmc6IDA7XG59XG5cbnVsLmNsaXN0IHtcbiAgbGlzdC1zdHlsZTogbm9uZSAhaW1wb3J0YW50O1xuICBwYWRkaW5nOiAwZW0gMCAwIDFlbTtcbn1cblxuLmNsaXN0IGEuZGJlbnRyeSxcbmEuY2ZvbGRlcixcbmEuY29sbGFwc2VkIHtcbiAgYmFja2dyb3VuZC1pbWFnZTogdXJsKC8rK3Bsb25lKytib290c3RyYXAtaWNvbnMvY2FyZXQtcmlnaHQuc3ZnKSAhaW1wb3J0YW50O1xuICBiYWNrZ3JvdW5kLXBvc2l0aW9uOiAwIDFweCAwIDAgIWltcG9ydGFudDtcbiAgYmFja2dyb3VuZC1yZXBlYXQ6IG5vLXJlcGVhdCAhaW1wb3J0YW50O1xuICBwYWRkaW5nLWxlZnQ6IDI0cHggIWltcG9ydGFudDtcbn1cblxuLmNsaXN0IGEuY2ZvbGRlciB7XG4gIGJhY2tncm91bmQtaW1hZ2U6IHVybCgvKytwbG9uZSsrYm9vdHN0cmFwLWljb25zL2Rhc2gtc3F1YXJlLnN2ZykgIWltcG9ydGFudDtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICBsaW5lLWhlaWdodDogMS40ZW07XG59XG5cbi5jbGlzdCBhLmNvbGxhcHNlZCB7XG4gIGJhY2tncm91bmQtaW1hZ2U6IHVybCgvKytwbG9uZSsrYm9vdHN0cmFwLWljb25zL3BsdXMtc3F1YXJlLnN2ZykgIWltcG9ydGFudDtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICBsaW5lLWhlaWdodDogMS40ZW07XG59XG5cbi8qIENTUyAqL1xuI2xpbmtjaGVja2VyIC5yb3csXG4jbGlua2NoZWNrZXIgLmNvbnRhaW5lciB7XG4gIG1hcmdpbi1ib3R0b206IDFlbTtcbn1cblxuI2xpbmtjaGVja2VyIGEge1xuICBkaXNwbGF5OiBibG9jaztcbiAgbWFyZ2luOiAwLjVlbTtcbiAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XG4gIGxpbmUtaGVpZ2h0OiAxLjVlbTtcbn1cblxuI2xpbmtjaGVja2VyIGJ1dHRvbiB7XG4gIG1hcmdpbi10b3A6IDNweDtcbn1cblxuI2xpbmtjaGVja2VyIGltZyB7XG4gIGJvcmRlcjogMHB4O1xuICBwYWRkaW5nOiA1cHg7XG59XG5cbiJdfQ== */